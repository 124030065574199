<!-- 招生系统基础配置 -- 学校目录管理 -- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onAdd="$router.push('./add')"
        has-add
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>

    <!-- 历史记录 -->
    <h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>
  </div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";
// import { getType}  from "@/api/common"
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      search:null,
      searchConfig:[
        {
          prop:"name",
          placeholder:"搜索学校名称"
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
      tableConfig:[
        {prop:"name", label:"学校正式名称"},
        {prop:"province", label:"省份"},
        {prop:"city", label:"城市"},
        {prop:"area", label:"区/县"},
        {prop:"local_name", label:"当地俗称"},
        {prop:"school_code", label:"学校识别码"},
        // {prop:"created_at", label:"创建时间"},
        {prop:"nature", label:"学校举办者类型"},
        {prop:"creator", label:"创建人"},
        {
          prop:"handle",
          label:"操作",
          width: "180rem",
          handle:true,
          render(row){
            return ["编辑","查看历史记录"]
          }
        },
      ],

      historyDialogShow: false,
      historyList: []
    }
  },
  created() {
    this.$store.commit("setPage",1);
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed:{
    ...mapState(["page"])
  },
  beforeRouteLeave(to, from, next) {
    // from.meta.keepAlive = true;
    next();
  },
  methods: {
    onSearch(val){
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    getData() {
      let params = {...this.search, page: this.page};
      this.loading = true;
      this.$_register('/api/recruit/school',{params: {...params}}).then(res => {
        // console.log(res)
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(()=>this.loading = false);
    },
    handleEdit(row, text) {
      if (text.type === 'edit') {
        this.$router.push('./edit?id=' + row.id)
      }

      if (text.type === 'viewHistory') {
        let params = {
          tbl_name: 'graduation_school',
          tbl_id: row.id
        }
        this.$_register.get('/api/recruit/common/get-opt-history',{params}).then(res => {
          let data = res.data.data
          this.historyList = data.list.length ? data.list : []
          this.historyDialogShow = true;
        })
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage",val);
      //拉取数据
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}
.button {
  // height: 100rem;
  //  padding-top: 30rem;
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}
.table {
  margin-left: 32rem;
}


::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}
.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}
.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-weight: normal;
  color: #3491fa;
}
</style>
